<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col xl="6" md="6">
        <ecommerce-statistics :data="statistics" />
      </b-col>
      <b-col xl="3" md="3">
        <ecommerce-order-chart :data="orderChart" />
      </b-col>
      <b-col xl="3" md="3">
        <ecommerce-profit-chart :data="profitChartQusAns" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="8" md="8"> 
          <ecommerce-revenue-report :data="revenueReport" />
      </b-col>
      <b-col xl="4" md="6">
          <ecommerce-earnings-chart :data="earningChartPaid" />
          <ecommerce-earnings-chart :data="earningChartWorthly" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="3" md="6">
        <statistic-card-with-area-chart
          v-if="teachersCount"
          icon="UsersIcon"
          color="warning"
          :statistic="teachersCount"
          statistic-title="الأساتذة"
          :chart-data="[{ type : '' , data : teacherMonthly }]"
        />
      </b-col>
       <b-col xl="3" md="6">
        <statistic-card-with-area-chart
          v-if="respondersCount"
          icon="UsersIcon"
          color="success"
          :statistic="respondersCount"
          statistic-title="مجيبو الأسئلة"
          :chart-data="[{ type : '' , data : responderMonthly}]"
        />
      </b-col>
       <b-col xl="3" md="6">
        <statistic-card-with-area-chart
          v-if="posCount"
          icon="UsersIcon"
          color="danger"
          :statistic="posCount"
          statistic-title="نقاط البيع"
          :chart-data="[{ type : '' , data : posMonthly}]"
        />
      </b-col>
       <b-col xl="3" md="6">
        <statistic-card-with-area-chart
          v-if="studentCount"
          icon="UsersIcon"
          :statistic="studentCount"
          statistic-title="الطلاب"
          :chart-data="[{ type : '' , data : studentMonthly}]"
        />
      </b-col>
    </b-row>

  </section>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import { kFormatter } from '@core/utils/filter'
import EcommerceStatistics from "./components/EcommerceStatistics";
import EcommerceOrderChart from "./components/EcommerceOrderChart.vue";
import EcommerceProfitChart from "./components/EcommerceProfitChart.vue";
import EcommerceRevenueReport from './components/EcommerceRevenueReport.vue'
import EcommerceEarningsChart from './components/EcommerceEarningsChart.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommerceProfitChart,
    StatisticCardWithAreaChart,
    EcommerceRevenueReport,
    EcommerceEarningsChart
  },
  data() {
    return {
      items: [],
      item : Object
    };
  },
  methods : {
      kFormatter,
      ...mapActions(['fetchHome'])
  },
  computed :{
    ...mapGetters(['statistics','orderChart','earningChartPaid','earningChartWorthly'
                  ,'studentMonthly','posMonthly','responderMonthly','teacherMonthly'
                  , 'teachersCount','studentCount','respondersCount','posCount'
                  , 'revenueReport','profitChartQusAns'])
  },
  created(){
    this.fetchHome()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>